<template>
  <!-- 实验室 -->
    <div class="content_box">
        <div class="left_box">
            <ul class="chat_records_box" id="chatRecordsBox">
                <li :class="`chat_records_item item_${item.type}`" v-for="(item, index) in chatRecordsList"
                    :key="index">
                    <div class="head">
                        <span v-if="item.type == 'right'">我</span>
                        <img src="@/assets/images/logo.png" alt="" style="width:70%" v-else>
                    </div>
                    <div class="content">{{ item.content }}</div>
                </li>
                <li class="chat_records_item item_left" v-if="isLoading || msgContent">
                    <div class="head">
                        <img src="@/assets/images/logo.png" alt="" style="width:70%">
                    </div>
                    <img src="@/assets/images/loading.gif" alt="" srcset="" style="width:5%" v-if="isLoading" />
                    <div class="content" v-else>{{ msgContent }}</div>
                </li>
            </ul>
            <el-input class="chat_question" v-model="chatContent" resize="none" placeholder="请输入内容"
                @keyup.enter.native="onSubmitChat">
                <i slot="suffix" class="iconfont lebo-send" @click="onSubmitChat"></i>
            </el-input>
            <!-- <div class="chat_question">
                <el-input v-model="chatContent" resize="none" type="textarea" rows="1" placeholder="请输入内容">
                </el-input>
                <el-button type="primary" @click="onSubmitChat">发送</el-button>
            </div> -->
        </div>
        <div class="right_box">
            <div class="card_heard_box">
                <span class="title">类型</span>
            </div>
            <ul class="ai_type_box">
                <li :class="['ai_type_item', currentAiType == item.id ? 'current' : '']" v-for="item in aiTypeList"
                    :key="item.id" @click="selectAitype(item.id)">
                    {{ item.name }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      chatContent: '', // 聊天内容
      currentAiType: 1, // 聊天类型
      aiTypeList: [
        { id: 1, name: '闲聊', subName: '开放式聊天' },
        { id: 2, name: '学生总结', subName: '将复杂的文本整理成一篇总结' },
        { id: 3, name: '面试题', subName: '创建面试问题' },
        { id: 4, name: '起名器', subName: '根据描述起名' },
        { id: 5, name: '关键词', subName: '从复杂的文本中提取关键字' },
        { id: 6, name: '情绪分析', subName: '将一段文本进行情绪检测' },
        { id: 7, name: '论文大纲', subName: '为论文主题生成大纲' },
        { id: 8, name: '书单推荐', subName: '' },
        { id: 9, name: '代码解释', subName: '' },
        { id: 10, name: '编程语言转换', subName: '' }
      ],
      chatWebSocket: null,
      isClose: false, // 是否判断
      msgContent: '', // 消息
      msgTimer: null,
      isLoading: false,
      chatRecordsList: [],
      isEndTimer: null,
      isEnd: true
    }
  },
  created () {
    this.initWebSocket()
    this.chatRecordsList = JSON.parse(sessionStorage.getItem('chatRecordsList')) || []
  },

  mounted () {
    // var chatRecordsBox = document.getElementById('chatRecordsBox');
    // chatRecordsBox.scrollTop = chatRecordsBox.scrollHeight;
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  destroyed () {
    this.chatWebSocket && this.chatWebSocket.onclose()
  },
  watch: {},
  methods: {
    initWebSocket () {
      const that = this
      if (typeof WebSocket === 'undefined') {
        this.$notify({
          title: '提示',
          message: '当前浏览器无法接收实时报警信息，请使用谷歌浏览器！',
          type: 'warning',
          position: 'bottom-right',
          duration: 0
        })
      } else {
        var wsUri
        if (location.href.split('/')[0] === 'https:') {
          wsUri = 'wss://visionmiracle.cn/ws?BelongingGroup=1&SendOrReceive=1'
        } else {
          wsUri = 'ws://visionmiracle.cn/ws?BelongingGroup=1&SendOrReceive=1'
        }
        // var wsUri = "ws://188.128.0.25:1000/ws?BelongingGroup=1&SendOrReceive=1";
        this.chatWebSocket = new WebSocket(wsUri)
        this.chatWebSocket.onopen = function (evt) {
          console.log('现在可以提问了')
          that.isClose = false
        }

        this.chatWebSocket.onclose = function (evt) {
          console.log('Disconnected')
          that.isClose = true
          that.reconnet()
        }

        this.chatWebSocket.onmessage = function (evt) {
          console.log('onmessage-----', evt.data)
          that.msgContent = that.msgContent + evt.data
          that.isLoading = false
          that.fnscrollTop()
          that.fnDebounce()
          // that.debunceEnd()
          if (that.isEndTimer) {
            clearTimeout(that.isEndTimer)
          }
        }

        this.chatWebSocket.onerror = function (evt, e) {
          console.log('Error occured: ' + evt.data)
        }
      }
    },
    fnscrollTop () {
      var chatRecordsBox = document.getElementById('chatRecordsBox')
      chatRecordsBox.scrollTop = chatRecordsBox.scrollHeight
      this.scrollHeight = chatRecordsBox.scrollHeight
    },
    fnDebounce () {
      // console.log(this.msgTimer);
      if (this.msgTimer) {
        clearTimeout(this.msgTimer)
      }
      this.msgTimer = setTimeout(() => {
        console.log('接口完成-----', this.msgContent)
        this.chatRecordsList.push({
          type: 'left',
          content: this.msgContent.trim()
        })
        this.msgContent = ''
        sessionStorage.setItem('chatRecordsList', JSON.stringify(this.chatRecordsList))
      }, 3000)
    },
    // 重新连接 WebSocket
    reconnet () {
      this.initWebSocket()
    },
    // 选择类型
    selectAitype (type) {
      this.currentAiType = type
    },
    onSubmitChat () {
      if (this.chatContent.trim().length === 0) return
      var option = {}
      option.prompt = this.chatContent
      switch (this.currentAiType) {
        case 1: // Q&A  问答
          option.model = 'text-davinci-003'
          option.temperature = 0
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0

          break
        case 2: // 二年级学生总结
          option.model = 'text-davinci-003'
          option.temperature = 0.7
          option.max_tokens = 2048
          option.top_p = 1.0
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          break
        case 3: // 面试问题
          option.model = 'text-davinci-003'
          option.temperature = 0.5
          option.max_tokens = 2048
          option.top_p = 1.0
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          break
        case 4: // 产品名称生成器
          option.model = 'text-davinci-003'
          option.temperature = 0.8
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          break
        case 5: // 关机词   从文本块中提取关键字。在较低的温度下，它从文本中选择关键字。在较高的温度下，它将生成相关的关键字，这有助于创建搜索索引。
          option.model = 'text-davinci-003'
          option.temperature = 0.5
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.8
          option.presence_penalty = 0.0
          break
        case 6: // Advanced tweet classifier  这是用于检测情绪的高级提示。它允许您为其提供状态更新列表，然后为每个更新提供情绪。
          option.model = 'text-davinci-003'
          option.temperature = 0
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          break
        case 7: // 论文大纲
          option.model = 'text-davinci-003'
          option.temperature = 0.3
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          break
        case 8: // 科幻书单制作工具
          option.model = 'text-davinci-003'
          option.temperature = 0.5
          option.max_tokens = 2048
          option.top_p = 1
          option.frequency_penalty = 0.52
          option.presence_penalty = 0.5
          option.stop = ['11.']
          break
        case 9: // 解释代码
          option.model = 'code-davinci-002'
          option.temperature = 0
          option.max_tokens = 4096
          option.top_p = 1
          option.frequency_penalty = 0.0
          option.presence_penalty = 0.0
          option.stop = ['"""']
          break
        case 10: // 翻译编程语言
          option.model = 'code-davinci-002'
          option.temperature = 0
          option.max_tokens = 4096
          option.top_p = 1
          option.frequency_penalty = 0
          option.presence_penalty = 0
          option.stop = ['###']
          break
        default:
          break
      }
      this.chatRecordsList.push({
        type: 'right',
        content: this.chatContent
      })
      this.$nextTick(() => {
        this.fnscrollTop()
      })
      sessionStorage.setItem('chatRecordsList', JSON.stringify(this.chatRecordsList))
      if (this.chatWebSocket.readyState != 1) {
        this.initWebSocket()
      }
      this.chatContent = ''
      this.isLoading = true
      this.chatWebSocket.send(JSON.stringify(option))
      this.debunceEnd()
    },
    debunceEnd () {
      if (this.isEndTimer) {
        clearTimeout(this.isEndTimer)
      }
      this.isEndTimer = setTimeout(() => {
        this.isLoading = false
        this.chatRecordsList.push({
          type: 'left',
          content: '连接超时...'
        })
      }, 5000)
    }
  }
}
</script>
<style scoped lang="less">
.content_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 10px;
    box-sizing: border-box;

    /deep/ .left_box {
        width: 82%;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);

        .chat_records_box {
            width: 100%;
            height: calc(100% - 90px);
            margin-bottom: 10px;
            border-radius: 5px;
            padding: 10px;
            box-sizing: border-box;
            overflow-y: auto;
            .chat_records_item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;

                .head {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #01a6fb;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    border: 2px solid #FFFFFF;
                    color: #fff;
                    font-size: 18px;
                }

                .content {
                    max-width: 80%;
                    // width: fit-content;
                    border-radius: 5px;
                    background-color: #f3f3f3;
                    // background-color: #b2e4fe;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #333333;
                    text-align: left;
                    position: relative;

                }
            }

            .item_right {
                flex-direction: row-reverse;

                .head {
                    margin-left: 10px;
                }

                .content {
                    &::before {
                        display: inline-block;
                        content: '';
                        border: 10px solid transparent;
                        border-left: 10px solid #f3f3f3;
                        position: absolute;
                        top: 10px;
                        right: -18px;
                    }
                }
            }

            .item_left {

                .head {
                    margin-right: 10px;
                }

                .content {
                    &::before {
                        display: inline-block;
                        content: '';
                        border: 10px solid transparent;
                        border-right: 10px solid #f3f3f3;
                        position: absolute;
                        top: 10px;
                        left: -18px;
                    }
                }
            }
        }

        .chat_question {
            width: 90%;
            height: 60px;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            input {
                height: 100%;
                border: none;
            }

            .el-input__suffix {
                display: flex;
                align-items: center;
                margin-right: 15px;

                .iconfont {
                    font-size: 30px !important;
                    cursor: pointer;
                }
            }
        }

    }

    /deep/ .right_box {
        width: 17%;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
        padding: 10px;
        box-sizing: border-box;

        .card_heard_box {
            padding-bottom: 10px;
            border-bottom: solid 1px rgba(1, 166, 251, 0.2);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                padding: 0px 10px;
                font-size: 18px;
                font-weight: 700;
                color: #01a6fb;
                border-left: 4px solid #01a6fb;
            }
        }

        .ai_type_box {
            margin-top: 10px;

            .ai_type_item {
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                background: #FFFFFF;
                border-radius: 5px;
                border: 1px solid #E6E6E6;
                margin-bottom: 10px;
                font-size: 16px;
                cursor: pointer;

                &::before {
                    display: flex;
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    border: 6px solid #E6E6E6;
                    margin-right: 5px;
                }
            }

            .current {
                color: #01A6FB;
                border: 1px solid #01A6FB;

                &::before {
                    border: 6px solid #01A6FB;
                }
            }
        }
    }
}
</style>
